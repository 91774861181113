import { Routes, Route } from 'react-router-dom';
import { PrivateRoute, MemoRouter } from './';

import { Apps, Cards } from '../pages';

export const BaseRouter = () => {
    return (
        <Routes>
            <Route path="/" element={<PrivateRoute />}>
                <Route path="/" element={<Apps />} />
                <Route path="/cards/*" element={<Cards />} />
                <Route path="/memos/*" element={<MemoRouter />} />
            </Route>
        </Routes>
    );
};
