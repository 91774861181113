import React, { createContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import axios from 'axios';
import { proxy } from '../hooks/useFetch';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const token = localStorage.getItem('authToken');
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const getAuthToken = () => {
        if (!location.search) {
            return navigate('/login');
        }
        axios({
            method: 'GET',
            url: `${proxy}/authenticate${location.search}`
        }).then((response) => {
            if (response.status === 200) {
                localStorage.setItem('authToken', response.data.access);
                navigate('/');
            }
        });
    };

    const checkAuthToken = () => {
        if (Boolean(token)) {
            const decodedJwt = jwt_decode(token);

            if (decodedJwt.exp * 1000 < Date.now()) {
                localStorage.removeItem('authToken');
                return handleRedirect();
            }
            setIsAuthenticated(true);
        } else {
            return handleRedirect();
        }
    };

    const handleRedirect = () => {
        setIsAuthenticated(false);
        if (location.pathname !== '/login') {
            navigate('/login');
        } else {
            getAuthToken();
        }
    };

    useEffect(() => {
        checkAuthToken();
    }, [location.pathname]); // eslint-disable-line

    return <AuthContext.Provider value={{ isAuthenticated, checkAuthToken }}>{children}</AuthContext.Provider>;
};

export { AuthProvider, AuthContext };
