import Button from './Button';

import css from './styles/modal.module.css';

export const Modal = ({ label, onClose, handleSubmit, children }) => {
    return (
        <div className={css['modal-container']}>
            <div className={css['modal-body']}>
                <div className={css['modal-title']}>
                    <p>{label}</p>
                    <Button cancel onClick={onClose} />
                </div>
                <div className={css['modal-content']}>{children}</div>
                <div className={css['modal-actions']}>
                    <Button label="Enregistrer" mode="add" onClick={handleSubmit} />
                    <Button label="Annuler" onClick={onClose} />
                </div>
            </div>
        </div>
    );
};
