import { useContext, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/auth';

function PrivateRoute() {
    const location = useLocation();
    const { isAuthenticated } = useContext(AuthContext);

    return isAuthenticated ? <Outlet /> : <Navigate to={location.pathname} />;
}

export default PrivateRoute;

export function ProtectedRoute() {
    const location = useLocation();
    const { isAuthenticated } = useContext(AuthContext);
    const [pin, setPin] = useState(null);
    const [isValidPin, setIsValidPin] = useState(false);

    if (!pin || !isValidPin) {
        return (
            <div>
                <p>test</p>
            </div>
        );
    }

    return isAuthenticated ? <Outlet /> : <Navigate to={location.pathname} />;
}
