import React, { useEffect, useState } from 'react';
import Icon from './Icon';

import './styles/header.css';

function HeaderButton({ text, mode, color, onClick, disabled = false, backlink = false }) {
    const [mobile, setMobile] = useState(false);
    const breakpoint = 600;

    useEffect(() => {
        const handleResizeWindow = () => {
            setMobile(window.innerWidth < breakpoint);
        };

        handleResizeWindow();

        window.addEventListener('resize', handleResizeWindow);
        return () => {
            window.removeEventListener('resize', handleResizeWindow);
        };
    }, []);

    if (backlink) {
        return (
            <div className="header-backlink" onClick={onClick}>
                <Icon name="return" />
                {!mobile && <span>Retour</span>}
            </div>
        );
    }

    return (
        <button className={`header-lgt ${mode}`} onClick={onClick} disabled={disabled}>
            {mobile ? <Icon name={text} color={color} /> : text}
        </button>
    );
}

export default HeaderButton;
