import { Routes, Route } from 'react-router-dom';

import { Index, Stocks, Notes, Lists } from '../pages/memos';

export const MemoRouter = () => {
    return (
        <Routes>
            <Route exact path={'/'} element={<Index />} />
            <Route exact path={'/notes'} element={<Notes />} />
            <Route exact path={'/stocks'} element={<Stocks />} />
            <Route exact path={'/lists'} element={<Lists />} />
        </Routes>
    );
};
