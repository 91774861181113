import axios from 'axios';
import Fetcher from './fetcher';

export default class Item extends Fetcher {
    constructor(options = {}) {
        super();
        this.path = 'memo/items';
        for (const [key, value] of Object.entries(options)) {
            this[key] = value;
        }
    }

    static listUrl(id) {
        if (!id) {
            return `memo/items`;
        }
        return `memo/items/${id}`;
    }

    save() {
        const url = `${window.config.api.prod}/memo/items`;
        return axios({
            method: this.id ? 'patch' : 'post',
            url: this.id ? `${url}/${this.id}` : url,
            headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
            data: this
        })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    }
}
