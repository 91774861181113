import React, { useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useFetch } from '../../../hooks/useFetch';

import { Lists as ListModel } from '../../../models';

import { Header, Loader, Icon, Filter } from '../../../components';
import { CreationModal, EditionModal } from '../../modal/ModalHandler';

import css from '../../pages.module.css';

export const Lists = () => {
    const [search, setSearch] = useState('');
    const [openModal, setOpenModal] = useState(false);

    const [lists, loadingLists, errors, syncLists] = useFetch({ method: 'GET', model: ListModel, search });

    return (
        <>
            <Header title="Listes" />
            <div className={css['list-container']}>
                <Filter searchable search={search} setSearch={setSearch} onAdd={() => setOpenModal(true)} />
                <CreationModal name="list" open={openModal} onClose={() => setOpenModal(false)} sync={syncLists} />

                <Loader loading={loadingLists} errors={errors}>
                    {lists?.map((it) => (
                        <ListItem key={it.id} item={it} sync={syncLists} />
                    ))}
                </Loader>
            </div>
        </>
    );
};

const ListItem = ({ item, sync }) => {
    const [openEditionModal, setOpenEditionModal] = useState(false);
    const handleDelete = useCallback(() => {
        item.delete().then(() => {
            toast.success('Liste supprimée avec succès!');
            sync();
        });
    }, [item, sync]);

    return (
        <>
            <EditionModal name="list" open={openEditionModal} data={item} onClose={() => setOpenEditionModal(false)} sync={sync} />
            <div className={css['app-link']}>
                <div className={css['app-link-block']} onClick={() => setOpenEditionModal(true)}>
                    <span>{item.label}</span>
                </div>
                <Icon name="delete" color="#b84040" onClick={() => handleDelete()} />
            </div>
        </>
    );
};
