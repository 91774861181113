import { useNavigate } from 'react-router-dom';

import { Header } from '../../components';

import css from './memos.module.css';

export { Lists } from './lists';
export { Notes } from './notes';
export { Stocks } from './stocks';

const APPS = [
    {
        name: 'Notes',
        url: 'notes',
        key: 0
    },
    {
        name: 'Stocks',
        url: 'stocks',
        key: 1
    },
    {
        name: 'Listes',
        url: 'lists',
        key: 2
    }
];

export const Index = () => {
    const navigate = useNavigate();
    return (
        <>
            <Header title="Mémos" />
            <div className={css['list-container']}>
                {APPS.map((it) => (
                    <div className={css['app-link']} key={it.key} onClick={() => navigate(`/memos/${it.url}`)}>
                        <p>{it.name}</p>
                    </div>
                ))}
            </div>
        </>
    );
};

// Encrypt(bytes('gAAAAABmnQg9ymkimMTBqthz4Dg2d6_1c8jGhdC29uw2GZnPwMSAuV3sldWFN45O-_EvO1igK3_T-1kX_bFQqtQSIf0tVaRV_naNni_0AVA3LkHduB8RsXmM1wqeLHDKzAH6waPxD5SYB7dPoYBgyirJUuNCbkBFwviRvixfXrOenCPetMTNzLYtMOaE-s31IV_2mj4ZykmPVg2gb8HNgIn2izb2jPE-dVxnwHm8BmPVf4czouMRQWHO8fXyPIN3U8V6Bsckm2h', 'utf-8')).decrypt_code()
