import React, { useMemo, useState } from 'react';
import * as Icons from './Icons';

function Icon({ className, tooltip = false, name = null, color = '#000', onClick }) {
    const [hover, setHover] = useState(false);

    const IconComponent = useMemo(() => {
        const icons = { ...Icons };
        let iconName = name?.charAt(0).toUpperCase() + name?.slice(1);

        return icons[iconName];
    }, [name]);

    const toggleTooltip = useMemo(() => {
        if (!tooltip) {
            return false;
        }
        return hover;
    }, [tooltip, hover]);

    if (!name) {
        return null;
    }

    return (
        <>
            <IconComponent
                className={className}
                onClick={onClick}
                style={{ fill: color, cursor: 'pointer' }}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            />
            {toggleTooltip && <span className="icon-tooltip"> {tooltip}</span>}
        </>
    );
}

export default Icon;
