import Fetcher from './fetcher';

export default class Lists extends Fetcher {
    constructor(options = {}) {
        super();
        this.path = 'memo/lists';
        for (const [key, value] of Object.entries(options)) {
            this[key] = value;
        }
    }

    static listUrl(id) {
        if (!id) {
            return `memo/lists`;
        }
        return `memo/lists/${id}`;
    }
}
