import { useMemo } from 'react';

import { Icon } from '../../components';

import './errors.css';

function Error({ error }) {
    const root = useMemo(() => {
        return window.location.pathname === '/';
    }, []);

    return (
        <div className="error-container">
            <Icon name="error404" color="red" />
            <span>Une erreur est survenue</span>
            <div>{error}</div>
            <div className="error-buttons-container">
                <button className="button-content" onClick={() => window.location.reload()}>
                    <Icon name="reload" color="#FFF" /> <span>Recharger</span>
                </button>
                {!root && (
                    <button className="button-content" onClick={() => window.history.go(-1)}>
                        <Icon name="back" color="#FFF" /> <span>Retour</span>
                    </button>
                )}
            </div>
        </div>
    );
}

export default Error;
