import axios from 'axios';
import { useState, useCallback, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from '../../context/auth';
import { useForm } from '../../hooks/useForm';
import { proxy } from '../../hooks/useFetch';

import { Loader } from '../../components';

import css from './login.module.css';

function Login() {
    const navigate = useNavigate();
    const { isAuthenticated } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [userForm, setUserForm] = useForm({
        username: null,
        password: null
    });

    const handleSubmit = useCallback(
        (e) => {
            e.preventDefault();
            setLoading(true);
            axios({
                method: 'POST',
                url: `${proxy}/auth/`,
                data: userForm
            })
                .then((response) => {
                    if (response.status === 200) {
                        localStorage.setItem('authToken', response.data.access);
                        navigate('/');
                        setLoading(false);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [userForm, navigate]
    );

    useEffect(() => {
        if (isAuthenticated) {
            return navigate('/');
        }
    }, []);

    return (
        <div className={css['login-container']}>
            <form className={css['oauth-wrapper']}>
                <Loader loading={loading}>
                    <div className={css['input-blocks']}>
                        <input type="text" autoComplete="off" required placeholder="Identifiant" onChange={(e) => setUserForm('username', e.target.value)} />
                    </div>

                    <div className={css['input-blocks']}>
                        <input type="password" autoComplete="off" required placeholder="Mot de passe" onChange={(e) => setUserForm('password', e.target.value)} />
                    </div>

                    <button type="submit" onClick={handleSubmit}>
                        Connexion
                    </button>
                </Loader>
            </form>
        </div>
    );
}

export default Login;
