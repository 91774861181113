import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

import { Products } from '../../../models';
import { useFetch } from '../../../hooks/useFetch';

import { Counter, Icon, Loader, Header, Filter } from '../../../components';
import { CreationModal, EditionModal } from '../../modal/ModalHandler';

import css from '../../pages.module.css';

export const Stocks = () => {
    const [search, setSearch] = useState('');
    const [openModal, setOpenModal] = useState(false);

    const [products, loadingProducts, errors, syncProducts] = useFetch({ model: Products, search });

    return (
        <>
            <Header title="Stocks" />
            <div className={css['list-container']}>
                <Filter searchable search={search} setSearch={setSearch} onAdd={() => setOpenModal(true)} />
                <CreationModal name="product" open={openModal} onClose={() => setOpenModal(false)} sync={syncProducts} />

                <Loader loading={loadingProducts} errors={errors}>
                    {products?.map((it) => (
                        <StockItem key={it.id} item={it} sync={syncProducts} />
                    ))}
                </Loader>
            </div>
        </>
    );
};

const StockItem = ({ item, sync }) => {
    const [openEditionModal, setOpenEditionModal] = useState(false);
    const handleSave = useCallback(
        (name, value) => {
            item[name] = value;
            item.save().then(() => {
                toast.success('Produit édité avec succès!');
                sync();
            });
        },
        [item, sync]
    );

    const handleDelete = useCallback(() => {
        item.delete().then(() => {
            toast.success('Produit supprimé avec succès!');
            sync();
        });
    }, [item, sync]);

    return (
        <>
            <EditionModal name="product" open={openEditionModal} data={item} onClose={() => setOpenEditionModal(false)} sync={sync} />
            <div className={css['app-link']}>
                <div className={css['app-link-block']} onClick={() => setOpenEditionModal(true)}>
                    <span>{item.name}</span>
                </div>
                <div className={css['app-link-cta']}>
                    <p>
                        <span>Qté:</span>
                        <Counter value={item.quantity} onChange={(value) => handleSave('quantity', value)} />
                    </p>
                    <p>
                        <span>Limite:</span>
                        <Counter value={item.limit} onChange={(value) => handleSave('limit', value)} />
                    </p>
                </div>
                <Icon name="delete" color="#b84040" onClick={() => handleDelete()} />
            </div>
        </>
    );
};
