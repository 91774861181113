import React, { useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useFetch } from '../../hooks/useFetch';

import { Cards as CardsModel } from '../../models';
import { capitalizeFirstLetter } from '../../utils';

import { CreationModal, EditionModal } from '../modal/ModalHandler';
import { Header, Loader, Icon, Filter } from '../../components';

import css from '../pages.module.css';

export const Cards = () => {
    const [search, setSearch] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [cards, loadingCards, errors, syncCards] = useFetch({ method: 'GET', model: CardsModel, search });

    return (
        <>
            <Header title="Cartes" />
            <div className={css['list-container']}>
                <Filter searchable search={search} setSearch={setSearch} onAdd={() => setOpenModal(true)} />
                <CreationModal name="card" open={openModal} onClose={() => setOpenModal(false)} sync={syncCards} />

                <Loader loading={loadingCards} errors={errors}>
                    {cards?.map((it) => (
                        <CardItem key={it.uuid} item={it} sync={syncCards} />
                    ))}
                </Loader>
            </div>
        </>
    );
};

const CardItem = ({ item, sync }) => {
    const [openEditionModal, setOpenEditionModal] = useState(false);
    const handleDelete = useCallback(() => {
        item.id = item.uuid;
        item.delete()
            .then(() => {
                toast.success('Carte supprimée avec succès!');
                sync();
            })
            .catch(() => {
                toast.error('Une erreur est survenue');
            });
    }, [item, sync]);

    const handleActivate = useCallback(() => {
        CardsModel.activate({
            id: item.uuid,
            value: !item.active
        })
            .then(() => {
                toast.success(`Carte ${item.active ? 'désactivée' : 'activée'} avec succès!`);
                sync();
            })
            .catch(() => {
                toast.error('Une erreur est survenue');
            });
    }, [item, sync]);

    return (
        <>
            <EditionModal name="card" open={openEditionModal} data={item} onClose={() => setOpenEditionModal(false)} sync={sync} />
            <div className={css['app-link']}>
                <div className={css['app-link-block']} onClick={() => setOpenEditionModal(true)}>
                    <span>{item.tag}</span>
                    <span className={css['app-link-block-user']}>{capitalizeFirstLetter(item.user.username)}</span>
                </div>
                <div>
                    <Icon className={css['app-link-icon']} name="active" color={item.active ? 'green' : '#b84040'} onClick={() => handleActivate()} />
                    <Icon className={css['app-link-icon']} name="delete" color="#b84040" onClick={() => handleDelete()} />
                </div>
            </div>
        </>
    );
};
