import { useState, useEffect, useCallback } from 'react';

import axios from 'axios';

const proxy = window.config.api.prod;

const fromApi = (model, id, data) => {
    if (!id) {
        if (!Array.isArray(data)) {
            data = [data];
        }
        return data.map((it) => new model(it));
    }
    return new model(data);
};

const handleQuery = (url, search, query) => {
    let output = url;
    if (!search && !query) {
        return output;
    }
    if (search) {
        output = `${url}?search=${search}`;
    }
    if (query) {
        output = `${output}${search ? '&' : '?'}${query}`;
    }
    return output;
};

const useFetch = ({ model, payload = null, id = null, query = null, search = null, launch = true, onSuccess = () => {} }) => {
    const [data, setData] = useState(null);
    const [errors, setErrors] = useState(null);
    const [loading, setLoading] = useState(false);

    const fetch = useCallback(() => {
        setLoading(true);

        axios({
            method: 'GET',
            url: handleQuery(`${proxy}/${model.listUrl(id)}`, search, query),
            headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
            data: payload
        })
            .then((response) => {
                const parsedResponse = fromApi(model, id, response.data);
                setData(parsedResponse);
                onSuccess(parsedResponse);
            })
            .catch((err) => {
                setErrors(err.message);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [id, model, payload, search, query, onSuccess, handleQuery]);

    useEffect(() => {
        if (launch) {
            fetch();
        }
    }, [launch, search, query]);

    return [data, loading, errors, fetch];
};

export { useFetch, proxy };
