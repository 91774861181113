import { CardModal, ListModal, NoteModal, ProductModal } from './Modals';

const ITEMS = [
    {
        name: 'product',
        component: ProductModal,
        initialData: {
            name: '',
            quantity: 1,
            limit: 5
        }
    },
    {
        name: 'note',
        component: NoteModal,
        initialData: {
            text: ''
        }
    },
    {
        name: 'list',
        component: ListModal,
        initialData: {
            label: '',
            items: []
        }
    },
    {
        name: 'card',
        component: CardModal,
        initialData: {
            label: '',
            tag: ''
        }
    }
];

class ModalHandler {
    constructor(item) {
        this.item = item;
        this.items = ITEMS;
        this.component = this.items.find((act) => act.name === this.item);
        this.datas = this.items.find((act) => act.name === this.item).initialData;
        this.validActions = this.items.map((act) => act.name);
        this.instantiate();
    }

    instantiate() {
        if (!ModalHandler.instance) {
            ModalHandler.instance = this;
        }
        return ModalHandler.instance;
    }

    validate() {
        if (!this.component || !this.validActions.includes(this.item)) {
            throw new Error(`${this.item} is not valid, please provide a valid item`);
        }
    }

    get_component() {
        this.validate();
        return this.component.component;
    }

    get_data() {
        return this.component.initialData;
    }
}

export const CreationModal = ({ name, open, onClose, sync }) => {
    if (!name || !open) {
        return null;
    }
    const component = new ModalHandler(name);
    const data = component.get_data();
    const ActionComponent = component.get_component();
    return <ActionComponent item={name} data={data} onClose={onClose} sync={sync} />;
};

export const EditionModal = ({ name, data, open, onClose, sync }) => {
    if (!name || !open) {
        return null;
    }
    const ActionComponent = new ModalHandler(name).get_component();
    return <ActionComponent item={name} data={data} onClose={onClose} sync={sync} />;
};
