import SearchBar from './SearchBar';
import HeaderButton from './HeaderButton';

import './styles/filter.css';

export const Filter = ({ onAdd = null, searchable = false, filters = [], search, setSearch }) => {
    return (
        <div className="filter-container">
            {searchable && <SearchBar value={search} onChange={setSearch} />}
            {onAdd && <HeaderButton text="Ajouter" mode="add" color="#0076b8" onClick={onAdd} />}
        </div>
    );
};
