export default class App {
    constructor(options = {}) {
        for (const [key, value] of Object.entries(options)) {
            this[key] = value;
        }
    }

    static listUrl(id) {
        return `home/apps`;
    }
}
