/**
 * This function takes an array of field to perform case insensitive filters within lists
 * @param {string} values - Values can be passed as a string "name" or as an array ["name", "start_date"]
 * @returns {boolean} The search's result
 */
export const matchSearch = (values, search) => {
    if (!Array.isArray(values)) {
        values = [values];
    }
    const reg = new RegExp(search, 'gi');
    return values.some((value) => reg.test(value));
};

export function capitalizeFirstLetter(string) {
    if (!string || string.length === 0) {
        return '';
    }

    return `${string.charAt(0).toUpperCase()}${string.slice(1).toLowerCase()}`;
}
