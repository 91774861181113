function Button({ cancel = false, label = '', mode = 'cancel', onClick, disabled = false }) {
    if (!cancel) {
        return (
            <button className={`action-btn ${mode}`} onClick={onClick} disabled={disabled}>
                {label}
            </button>
        );
    }
    return (
        <span className="cancel-btn" onClick={onClick}>
            X
        </span>
    );
}

export default Button;
