import { Fetcher } from '.';

export default class Stocks extends Fetcher {
    constructor(options = {}) {
        super();
        this.path = 'memo/products';
        for (const [key, value] of Object.entries(options)) {
            this[key] = value;
        }
    }

    static listUrl(id) {
        if (!id) {
            return `memo/products`;
        }
        return `memo/products/${id}`;
    }
}
